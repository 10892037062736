export const getBaseUrl = () => {
    const { protocol, hostname, port } = window.location;
    return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
};


export const getUrlParameters = () => {
    const params = new URLSearchParams(window.location.search);
    const paramsObject = {};
    for (const [key, value] of params.entries()) 
        paramsObject[key] = value;
    return paramsObject;
};


export const createCookieIfNotExists = (name, value) => {
    if( getCookie("user_id") === null ) {
        let expires = "";
        let days = 1000;
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
        const domain = "; domain=." + (window.location.hostname.includes("www.") ? window.location.hostname.replace("www.", "") : window.location.hostname);
        document.cookie = name + "=" + (value || "") + expires + "; path=/" + domain;
    } 
    return getCookie("user_id")
};


export const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};


export const generateUserId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let userId = '';
    for (let i = 0; i < 20; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        userId += characters[randomIndex];
    }
    return userId;
}


export const normalized_1X2_probabilities = (_1X2_odds_array) => {
    let normalized_implied_probs = []
    let normalizer = 1.0/_1X2_odds_array[0] + 1.0/_1X2_odds_array[1] + 1.0/_1X2_odds_array[2]
    for( let i = 0; i < _1X2_odds_array.length; ++i ) 
        normalized_implied_probs.push( (1.0/_1X2_odds_array[i])/normalizer )
    return normalized_implied_probs
}

// i18n object aus "const { i18n } = useTranslation();"  ist erforderlich, weil useTranslation() nur innerhalb einer Component funktioniert und nicht hier! 
export const get_iso_locale = (i18n) => { return i18n.language.split('-')[0] }