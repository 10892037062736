import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const ValueBettingAnalysisConfidence = ({ percentDifference }) => {

    const [redCSS, setRedCSS] = useState("")
    const [yellowCSS, setYellowCSS] = useState("")
    const [greenCSS, setGreenCSS] = useState("")
    const {t} = useTranslation()

    useEffect( () => {
        setRedCSS("d-flex align-items-center justify-content-center ValueBettingAnalysisConfidence_js__red text-center " + (percentDifference <= 7 ? "ValueBettingAnalysisConfidence_js__big" : "ValueBettingAnalysisConfidence_js__normal"))
        setYellowCSS( "d-flex align-items-center justify-content-center ValueBettingAnalysisConfidence_js__yellow text-center " +  (7 < percentDifference && percentDifference <= 25 ? "ValueBettingAnalysisConfidence_js__big" : "ValueBettingAnalysisConfidence_js__normal") )
        setGreenCSS( "d-flex align-items-center justify-content-center ValueBettingAnalysisConfidence_js__green text-center " +  (25 < percentDifference ? "ValueBettingAnalysisConfidence_js__big" : "ValueBettingAnalysisConfidence_js__normal") )
    }, [percentDifference])

    return (
        <div className="d-flex w-100 flex-column align-items-center justify-content-center ValueBettingAnalysisConfidence_js__container_div py-2 px-1 mt-2">
            <div className="d-flex w-100 flex-row align-items-end justify-content-center">
                <p className="p-0 m-0">{t("ValueBettingAnalysisConfidence_js__your_confidence_p")}</p>
            </div>
            <div className="d-flex w-100 flex-row align-items-end justify-content-center pt-1 m-0">
                <div className={redCSS}>{ percentDifference <= 7 ? t("ValueBettingAnalysisConfidence_js__red_p") : "" }</div>
                <div className={yellowCSS}>{ 7 < percentDifference && percentDifference <= 25 ? t("ValueBettingAnalysisConfidence_js__yellow_p") : ""}</div>
                <div className={greenCSS}>{25 < percentDifference ? t("ValueBettingAnalysisConfidence_js__green_p") : ""}</div>
            </div>
        </div>)
}

export default ValueBettingAnalysisConfidence