import React, { Fragment, useEffect, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LandingPage1 from './landingpages/LandingPage-Nov-2023-v1';
import ThankYou1 from './landingpages/ThankYou-Nov-2023-v1';
import LandingPageSafe from './landingpages/LandingPage-SafeVersion';
import { useLocation } from 'react-router-dom';
import Survey from './survey/Survey';
import AffiliateLinkNotFound from './AffiliateLinkNotFound';
import { createCookieIfNotExists, generateUserId } from './helpers/Utils';
import ValueBettingAnalysis from './valuebetting/ValueBettingAnalysis';
import LoadingIndicator from './helpers/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const ValueBetting = React.lazy(() => import('./valuebetting/ValueBetting'));

function App() {

    const {t} = useTranslation()
    let query = new URLSearchParams(useLocation().search);
    window.verified = query.get("vf") === "k" || window.verified

    window.baseURL = document.getElementById("url").innerHTML;

    useEffect( () => { 
        createCookieIfNotExists("user_id", generateUserId() ) 
    }, [])

    /*
        rsync always comes preinstalled with MacOS

        NOTE: The trailing "/" slash in the source path is important !!! Otherwise the subfolder "build" will be created in "static" on the remote machine.

        rsync -av --exclude='images' /Users/Tom/MeineDaten/Programmieren/Sportwetten/Wettprofi-Newsletter/wettprofi-newsletter-react-nov-2023/build/ cryptorocket:/opt/Wettprofi-Newsletter/Wettprofi-Newsletter-Backend/static
        rsync -av /Users/Tom/MeineDaten/Programmieren/Sportwetten/Wettprofi-Newsletter/wettprofi-newsletter-react-nov-2023/build/ cryptorocket:/opt/Wettprofi-Newsletter/Wettprofi-Newsletter-Backend/static

        -a : Archive mode; it preserves symbolic links, permissions, timestamps, and other attributes.
        -v : Verbose mode; it provides detailed output during the operation.
        --exclude='images': Excludes the directory named images from the operation.

     */
    return (
        <Fragment>
            <Routes>
                <Route path="/lp1" element={ <LandingPage1/> } />
                <Route path="/lps" element={ <LandingPageSafe/> } />
                
                {/* Suspense is used to show a fallback while loading the component */}
                <Route path="/valuebetting" element={
                    <Suspense fallback={<LoadingIndicator loading_text={t("LoadingIndicator_js__loading_text")} />}>
                        <ValueBetting />
                    </Suspense>
                }/>
                <Route path="/valuebetting_analysis" element={
                    <Suspense fallback={<LoadingIndicator loading_text={t("LoadingIndicator_js__loading_text")} />}>
                        <ValueBettingAnalysis />
                    </Suspense>
                }/>

                {/* Shown after sign-up */}
                <Route path="/thank-you-nov-2023-v1" element={ <ThankYou1/> } />
                {/* This is the route that gets called from the incentive mail from convertkit (the email from convertkit that makes you click to confirm your newsletter signup)*/}
                <Route path="/email-confirmation" element={ <Survey/> } />
                <Route path="/survey" element={ <Survey/> }/>
                <Route path="/affiliate-link-not-found" element={ <AffiliateLinkNotFound/> } />
                {/* mit replace-Attribut wird kein Push ausgeführt */}
                <Route path="/" element={<Navigate replace to={window.verified ? "/lp1" : "/lps"} />}/>
                { /* Falls nichts des Obenigen zutrifft */}
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
        </Fragment>
    );
}

export default App;
