import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import DateTimeFormatter from "../helpers/DateTimeFormatter"
import { Button, Spinner } from "react-bootstrap"
import ValueBettingAnalysisConfidence from "./ValueBettingAnalysisConfidence"
import useHttp from '../hooks/useHttp';
import { getUrlParameters, createCookieIfNotExists, generateUserId } from '../helpers/Utils';

const ValueBettingAnalysisRow = ({ match_dict }) => {

    const [resultType, setResultType] = useState("")
    const [percentDifference, setPercentDifference] = useState(0)
    const clubsLookup = useSelector(state => state.sd.clubs)
    const leaguesLookup = useSelector(state => state.sd.leagues)
    const [clubIconSize, setClubIconSize] = useState("80%")
    const [leagueIconSize, setLeagueIconSize] = useState("80%")
    const [leagueIconMarginTop, setLeagueIconMarginTop] = useState(0)
    const [forwardToBookieBtnPressed, setForwardToBookieBtnPressed] = useState(false)
    const [ckSubscriberId, setCkSubscriberId] = useState("")
    const { error, sendRequest } = useHttp();
    const [httpError, setHttpError] = useState("")
    const { t, i18n } = useTranslation()
    
    /*
    Forward Link aus der Email sollte wie folgt aussehen: https://ai-saas-assistant.com/valuebetting?action=value_betting_email_btn1&iso=de

    Enthaltene Parameter: 
    1. iso
    2. action
    */

    useEffect( () => {
        let param_dict = getUrlParameters()
        const ck_subscriber_id = param_dict["ck_subscriber_id"]
        setCkSubscriberId(ck_subscriber_id)

        let view_port_width = window.innerWidth
        const _clubIconSize = Math.floor(view_port_width/6.5)
        const _leagueIconSize = Math.floor(view_port_width/10.5)
        setClubIconSize( _clubIconSize + "px")
        setLeagueIconSize( _leagueIconSize + "px")
        setLeagueIconMarginTop( Math.floor( _clubIconSize - _leagueIconSize)/2 ) 
        let tmpResultType = match_dict.n_probas[0] < (match_dict.probability/100.0) ? "Home" : "Alternative"
        setResultType( tmpResultType ) 
        setPercentDifference( tmpResultType === "Home" ? Math.floor( match_dict.probability - match_dict.n_probas[0]*100) : Math.floor( (100-match_dict.probability) - (100-match_dict.n_probas[0]*100) ) )
    }, [match_dict])


    const forwardToBookieCallback = () => {
        setForwardToBookieBtnPressed(true)
        setHttpError("")
        let urlParams = getUrlParameters()
        const simple_iso_code = i18n.language.split('-')[0]
        const requestConfig = {
            url : window.baseURL + "data_service",
            method : "POST",
            headers : { "Content-Type" : "application/json" },
            body: { 
                "service_type": "forward_to_bookie", 
                "iso" : urlParams.iso ? urlParams.iso : simple_iso_code, 
                "bookie" : match_dict.bookie_id,
                "ck_subscriber_id" : ckSubscriberId,
                "user_id" : createCookieIfNotExists("user_id", generateUserId() ), // Do not forget to always send the user_id along the request
            },
            onSuccessFunction : (responseJson) => {
                console.log(JSON.stringify(responseJson))
                if(responseJson.result === "success") {
                    // redirect client:
                    window.location.href = responseJson.al
                } else if(responseJson.result === "failed") {
                    window.location.href = `${window.baseURL}affiliate-link-not-found?bn=${responseJson.elp.bn}&ci=${responseJson.elp.iso}` 
                } else {
                    setHttpError(error)
                }
            }
        };
        sendRequest(requestConfig);
    }


    return (
        <div className="d-flex align-items-center justify-content-center flex-column w-100">
            <div className="d-flex align-items-center justify-content-center flex-column w95 py-3 mb-3 ValueBettingAnalysisRow_js__match_div">
                <DateTimeFormatter str_date={match_dict.datetime}/>
                
                <div className="w-100" style={{ position : "relative"}}>
                    <div className="d-flex align-items-center justify-content-evenly w-100" style={{ position: 'absolute', zIndex: 1, top : leagueIconMarginTop }}>
                        <img style={{ width: leagueIconSize, height: leagueIconSize }} src={`${window.baseURL}images/league_icons/${match_dict.league_id}.png`} alt={`${leaguesLookup[match_dict.league_id]} logo`} />
                    </div>
                    <div className="d-flex align-items-center justify-content-evenly w-100" style={{ position: 'relative', zIndex: 2 }}>
                        <div className="d-flex align-items-center justify-content-center flex-column" style={{ width: '50%' }}>
                            <img style={{ width: clubIconSize, height: clubIconSize }} src={`${window.baseURL}images/club_icons/${match_dict.club1_id}.png`} alt={`Club logo of ${match_dict.club1_id}`} />
                            <p className="p-0 pt-2 m-0 text-center">{clubsLookup[match_dict.club1_id]}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column" style={{ width: '50%' }}>
                            <img style={{ width: clubIconSize, height: clubIconSize }} src={`${window.baseURL}images/club_icons/${match_dict.club2_id}.png`} alt={`Club logo of ${match_dict.club2_id}`} />
                            <p className="p-0 pt-2 m-0 text-center">{clubsLookup[match_dict.club2_id]}</p>
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center justify-content-center flex-column w-100">

                    <p className="p-0 m-0 mt-3 text-center">{t("ValueBettingAnalysisRow_js__estimate_title_p", { clubname : clubsLookup[match_dict.club1_id]})}</p>

                    <div className="d-flex align-items-center justify-content-center w-100">
                        <p className="p-0 pe-1 m-0 w50 text-end">{t("ValueBettingAnalysisRow_js__your_estimate_p")}</p>
                        <p className="p-0 ps-1 m-0 w50">{  match_dict.probability }%</p>
                    </div>

                    <div className="d-flex align-items-center justify-content-center w-100">
                        <p className="p-0 pe-1 m-0 w50 text-end">bet365:</p>
                        <p className="p-0 ps-1 m-0 w50">{  Math.floor(match_dict.n_probas[0]*100) }%</p>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center flex-column w-100 px-3">

                    <h3 className="p-0 m-0 mt-3 text-center">{t("ValueBettingAnalysisRow_js__analysis_result")}</h3>
                    <div className="d-flex w-100 flex-column">
                        { resultType === "Home" && 
                                <p className="p-0 m-0 mt-2 text-center">
                                    { t("ValueBettingAnalysisRow_js__result_home_line_1", { percent : percentDifference, clubname : clubsLookup[match_dict.club1_id], bookie_name : "bet365" }) }
                                </p>
                        }
                        { resultType === "Alternative" && 
                            <div>
                                <p className="p-0 m-0 mt-2 text-center">
                                    { t("ValueBettingAnalysisRow_js__result_alternative_line_1", { percent : percentDifference, clubname : clubsLookup[match_dict.club1_id], bookie_name : "bet365" }) }
                                </p>
                                <p className="p-0 m-0 mt-1 text-center">
                                    { t("ValueBettingAnalysisRow_js__result_alternative_line_2", { clubname: clubsLookup[match_dict.club2_id]})}
                                </p>
                            </div>
                        }
                        <ValueBettingAnalysisConfidence percentDifference={percentDifference}/>
                        { !forwardToBookieBtnPressed && <Button onClick={forwardToBookieCallback} className="mt-3" variant="warning">{t("ValueBettingAnalysisRow_js__bet_home")}</Button> }
                        { forwardToBookieBtnPressed && 
                            <div className="w-100 d-flex justify-content-center align-items-center pt-3">
                                <Spinner animation="border" role="status" style={{ color: 'yellow', width: '3rem', height: '3rem' }}/>
                            </div>
                        }
                        { 0 < httpError.length && <p className="error-p text-center mt-2">{httpError}</p>}
                    </div>
                </div>
            </div>
        </div>)

}

export default ValueBettingAnalysisRow