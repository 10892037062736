import { useSelector } from "react-redux"
import ValueBettingAnalysisRow from "./ValueBettingAnalysisRow"
import { useTranslation } from "react-i18next"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import useHttp from '../hooks/useHttp';
import { getUrlParameters, createCookieIfNotExists, generateUserId } from '../helpers/Utils';

const ValueBettingAnalysis = () => {

    const { sendRequest } = useHttp();
    const navigate = useNavigate();
    const {t, i18n } = useTranslation()
    const matches = useSelector(state => state.vb.matches )


    useEffect( () => {
        let urlParams = getUrlParameters()
        let param_dict = getUrlParameters()
        const ck_subscriber_id = param_dict["ck_subscriber_id"]
        const simple_iso_code = i18n.language.split('-')[0]
        const requestConfig = {
            url : window.baseURL + "data_service",
            method : "POST",
            headers : { "Content-Type" : "application/json" },
            body: { 
                "service_type": "remote_logging", 
                "log_event" : "ValueBettingAnalysis",
                "iso" : urlParams.iso ? urlParams.iso : simple_iso_code, 
                "ck_subscriber_id" : ck_subscriber_id,
                "user_id" : createCookieIfNotExists("user_id", generateUserId() ), // Do not forget to always send the user_id along the request
            },
            onSuccessFunction : (responseJson) => {
                console.log(JSON.stringify(responseJson))
            }
        };
        sendRequest(requestConfig);
    }, [sendRequest, i18n])


    return (
        <div className="d-flex align-items-center justify-content-center flex-column">
            <h1 className="white mt-3">{t("ValueBettingAnalysis_title")}</h1>
            <h1 className="white">{t("ValueBettingAnalysis_subtitle")}</h1>
            <p className="white text-center w-100 mt-2">{t("ValueBettingAnalysis_explanation")}</p>
            { matches.map((match, index) => ( <ValueBettingAnalysisRow key={index} match_dict={match} /> ))}
            { matches.length === 0 && 
                <Button variant="warning mt-5" onClick={ () => { navigate("/valuebetting"); } }>{t("ValueBettingAnalysis_restart")}</Button>
            }
        </div>)
}

export default ValueBettingAnalysis